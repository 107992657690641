import Vuex from 'vuex'
import mapLayers from './modules/map-layers'
import states from './modules/states'
import cities from './modules/cities'
import geoserver from './modules/geoserver'
import forestActBigNumbers from './modules/forest_act/bignumbers'
import forestActDeficits from './modules/forest_act/deficits'
import forestActLtenure from './modules/forest_act/ltenure'
import filterLandTenure from './modules/filters/landtenure'
import filterGetSizes from './modules/filters/sizes'
import dashboardForestAct from './modules/dashboard/forest-act'
import dashboardRankMun from './modules/dashboard/forest-act-rank-mun'
import dashboardRankUf from './modules/dashboard/forest-act-rank-uf'
import dashboardLandTenure from './modules/dashboard/land_tenure'

export default new Vuex.Store({
  modules: {
    mapLayers,
    states,
    cities,
    geoserver,
    forestActBigNumbers,
    forestActDeficits,
    forestActLtenure,
    filterLandTenure,
    filterGetSizes,
    dashboardForestAct,
    dashboardRankMun,
    dashboardRankUf,
    dashboardLandTenure
  }
})