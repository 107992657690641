  // initial state
  const state = () => ({
    all: [],
    allLegend: Blob,
    loadingStatus:true,
    loadingStatusLegend:true
  })
  

  // getters
  const getters = {
    loadingStatusLegend (state) {
      return state.loadingStatusLegend
    },
    loadingStatus (state) {
      return state.loadingStatus
    },
    getBbox: (state) => {
       return state.all.bbox
    },
    getGeoJsonObject: (state) => {
      return state.all
   },
    getLegend: (state) => {
       return state.allLegend
    }
  }
  
  // actions
  const actions = {
    getGeoDataByCdMun ({ commit }, cd_mun) {
      commit('loadingStatus',true)
      fetch(process.env.VUE_APP_GEO_SERVER+`imaflora/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=imaflora%3Apa_br_municipios&outputFormat=application%2Fjson&cql_filter=cd_mun=`+cd_mun)
      .then(response => response.json())
      .then(function(data) {
        commit('setGeoData', {data})
        commit('loadingStatus',false)
    });
    },
    getGeoDataByCdUf ({ commit }, cd_uf) {
      commit('loadingStatus',true)
      fetch(process.env.VUE_APP_GEO_SERVER+`imaflora/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=imaflora%3Apa_br_estados&outputFormat=application%2Fjson&cql_filter=cd_uf=`+cd_uf)
      .then(response => response.json())
      .then(function(data) {
        commit('setGeoData', {data})
        commit('loadingStatus',false)
    });
    },
    async getLegend ({ commit }, [endpoint,layer,style]) {
      // console.log("getLegend")
      commit('loadingStatusLegend',true)
      return await fetch(process.env.VUE_APP_GEO_SERVER+endpoint+`/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&legend_options=fontAntiAliasing:true;forceLabels:on&LAYER=`+layer+`&STYLE=`+style)
      .then(response => response.blob())
      .then(function(data) {
        // console.log("data")
        commit('setGeoLegend',data)
        commit('loadingStatusLegend',false)
        return data
    });
    },
  }
  
  // mutations
  const mutations = {
      setGeoData (state, { data }) {
          state.all = data
      },
      setGeoLegend (state,data) {
        state.allLegend = ''
         state.allLegend = data
      },  
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      },
      loadingStatusLegend(state, newLoadingStatus) {
        state.loadingStatusLegend = newLoadingStatus
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }