export function stateIsError(state) {
    if(state == undefined || state == null) {
        return true;
    }     
    else if(state.all == undefined) {
        return true;
    }
    //else if(state.all[0] == undefined) {
    //    return true;
    //}
    else if(state.all.error !== undefined) {
        return (state.all.error.length > 0)
    }
    else if(Array.isArray(state.all) && state.all.length <= 0) {
        return true;
    }
    else {
        return false;
    }      
}

export function nullToZero(value) {
    return value == null ? 0 : value;
}