import {setFormatForTable} from '../../../modules/table.utils'
import { stateIsError } from '../../../modules/api.utils';

  // initial state
  const state = () => ({
    allNotPaged: [],
    all: [],
    loadingStatus:true,
    loadingStatusPaged:true,
    page: 0
  })

  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    loadingStatusPaged (state) {
      return state.loadingStatusPaged
    },
    getRankMun: (state) => {
      if(stateIsError(state)) {
        return null;
      }
      return state.allNotPaged
    },
    getRankMunPaged: (state) => {
      if(stateIsError(state)) {
        return null;
      }
      return setFormatForTable(state)
    }
  }
  
  // actions
  const actions = {
    getRankMun ({ commit }) {
      setTimeout(() => {
        commit('loadingStatus',true)
        fetch(process.env.VUE_APP_API_URL+`dashboard/forest_act_rank_mun`,{method: "POST",headers: { "Content-Type": "application/json" }})
        .then(response => response.json())
        .then(function(result) {
          let data = result.results
          commit('setRankMun', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setRankMun', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
    getRankMunPaged ({ commit, state }) {
      setTimeout(() => {
        commit('loadingStatusPaged',true)
        fetch(process.env.VUE_APP_API_URL+`dashboard/forest_act_rank_mun?page=` + state.page + `&limit=20` ,{method: "POST",headers: { "Content-Type": "application/json" }})
        .then(response => response.json())
        .then(function(result) {
          let data = result.results
          commit('setRankMunPaged', {data})
          commit('loadingStatusPaged',false)
        })
        .catch(error => {
            commit('setRankMunPaged', null)
            commit('loadingStatusPaged',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
  }
  
  // mutations
  const mutations = {
      setRankMun (state, { data }) {
        state.allNotPaged = data
      },
      loadingStatus(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
      },
      setRankMunPaged (state, { data }) {
        state.all = data
      },
      setPage(state,page) {
        state.page = page
      },
      loadingStatusPaged(state, newLoadingStatus) {
        state.loadingStatusPaged = newLoadingStatus
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }