
import {createApp } from 'vue'
import App from './components/pages/App.vue'
import store from './store'
import i18n from './i18n'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { formatNumber } from "./modules/numeric.utils.js"

import Loader from './components/atoms/Loader'
import routes from './routes'
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App).use(i18n).use(routes).use(store)
app.config.globalProperties.emitter = emitter
app.config.globalProperties.$filters = {
    formatNumber(number) {
        return formatNumber(number)
    }
}
app.component('loader', Loader)
app.mount('#app')
