import jsonData from '../../map-layers.json';

// initial state
const state = () => ({
  allFilters: [],
  allLayers: [],
  loadingStatus:true
})

function flatFilter(nestedProp, compareKey, compareId, arr) {
  var result = undefined;
  arr.forEach(function f(o) {
    // Achou o item
    if (o[compareKey] !== undefined && o[compareKey].source == compareId) {
      result = o;
      return
    }

    // Tem subiitem e não encontrou 
    if (o[nestedProp]) {
      o[nestedProp].forEach(f);
    }
  });
  return result;
}

// getters
const getters = {
  getSelectedFilterByKey: (state) => (key,value) => {
      let filter = flatFilter('subitems', key, value, state.allFilters)
      return filter
  },
  getLayers: (state) => {
    return state.allLayers
  },
  getFilters: (state) => {
    return state.allFilters
  },
  loadingStatus (state) {
    return state.loadingStatus
  }
}

// actions
const actions = {
  getAllLayers ({ commit }) {
    commit('loadingStatus',true)
    if (jsonData) {
        commit('setFilters', {layers: jsonData["accordion-items"]})
        commit('setLayers', {layers: jsonData["layers"]})
        commit('loadingStatus',false)
    }
  },
}

// mutations
const mutations = {
   setFilters (state, { layers }) {
        state.allFilters = layers
    },
    setLayers (state, { layers }) {
      state.allLayers = layers
  },
    loadingStatus(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}