import {setFilter} from '../../../modules/filter.utils'
import { stateIsError } from '../../../modules/api.utils';
import t from '../../../modules/translates.utils';

  // initial state
  const state = () => ({
    all: [],
    filters: [],
    filtersFormated: [],
    loadingStatus:true
  })

  const setFormatForChart = (state,view,chartLabel) => {
    let labels = [];
    let values = [];
    state.all.forEach((item,index) => { 
      labels[index] = t(item[chartLabel].toLowerCase())
      values[index] = item[view]
    })
    return [labels,values]
  }

  const formaVerticalBarChartGidxArea = (state) => {
    let chartLabel = "Tamanho"
    let labels = []
    let count = []
    let area_ha = []
    let totalAreaHa = 0
    let totalCount = 0
    let percentAreaHa = 0
    let percentCount = 0
    state.all.forEach((item) => { 
      totalAreaHa += parseFloat(item["Área (ha)"])
      totalCount += parseFloat(item["Número de Imóveis"])
    })
    state.all.forEach((item,index) => { 
      percentAreaHa = (item["Área (ha)"] / totalAreaHa) * 100
      percentCount = (item["Número de Imóveis"] / totalCount) * 100
      labels[index] =  t(item[chartLabel].toLowerCase())
      area_ha[index] = Math.round(percentAreaHa)
      count[index] = Math.round(percentCount)
    })
    return [labels,count,area_ha]
  }


  const formaVerticalBarChartNV = (state) => {
    let chartLabel = "Tamanho"
    let labels = []
    let inApp = []
    let outApp = []
    let exced = []
    let cra = []
    let totalInApp = 0
    let totalOutApp = 0
    let totalExced = 0
    let totalCra = 0
    let percentInApp = 0
    let percentOutApp = 0
    let percentExced = 0
    let percentCra = 0
    state.all.forEach((item) => { 
      totalInApp += parseFloat(item["Vegetação Nativa dentro de APP"])
      totalOutApp += parseFloat(item["Vegetação Nativa fora de APP"])
      totalExced += parseFloat(item["Vegetação Nativa Excedente"])
      totalCra += parseFloat(item["CRA"])
    })
    state.all.forEach((item,index) => { 
      percentInApp = (item["Vegetação Nativa dentro de APP"] / totalInApp) * 100
      percentOutApp = (item["Vegetação Nativa fora de APP"] / totalOutApp) * 100
      percentExced = (item["Vegetação Nativa Excedente"] / totalExced) * 100
      percentCra = (item["CRA"] / totalCra) * 100
      labels[index] = t(item[chartLabel].toLowerCase())
      inApp[index] = Math.round(percentInApp)
      outApp[index] = Math.round(percentOutApp)
      exced[index] = Math.round(percentExced)
      cra[index] = Math.round(percentCra)
    })
    return [labels,inApp,outApp,exced,cra]
  }  


  const formaVerticalBarChartAT = (state) => {
    let chartLabel = "Tamanho"
    let labels = []
    let count = []
    let area_ha = []
    let totalAreaHa = 0
    let totalCount = 0
    let percentAreaHa = 0
    let percentCount = 0
    state.all.forEach((item) => { 
      totalAreaHa += parseFloat(item["Área Antrópica dentro de APP"])
      totalCount += parseFloat(item["Área Antrópica fora de APP"])
    })
    state.all.forEach((item,index) => { 
      percentAreaHa = (item["Área Antrópica dentro de APP"] / totalAreaHa) * 100
      percentCount = (item["Área Antrópica fora de APP"] / totalCount) * 100
      labels[index] = t(item[chartLabel].toLowerCase())
      area_ha[index] = Math.round(percentAreaHa)
      count[index] = Math.round(percentCount)
    })
    return [labels,count,area_ha]
  }

  const getSumByKey = (arr, key) => {
    return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
  }

  // getters
  const getters = {
    getDashboardData: (state) => {
      return state.all
    },
    getBigNumberCountGid: (state) => {
      if(stateIsError(state)) {
        return 0;
      }
      let totalSum = getSumByKey(state.all,'Número de Imóveis')
      return totalSum
    },
    getBigNumberTotalAreaHa: (state) => {
      if(stateIsError(state)) {
        return 0;
      }
      let totalSum = getSumByKey(state.all,'Área (ha)')
      return totalSum
    },
    getVerticalBarChartGidxArea: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      let chartData = formaVerticalBarChartGidxArea(state)
      const data = {
        labels: chartData[0],
        datasets: [
          {
            label: "% gid",
            data: chartData[1],
            borderColor: "#00853F",
            backgroundColor: "#00853F"
          },
          {
            label: "% area",
            data:chartData[2],
            borderColor: "#0081C6",
            backgroundColor: "#0081C6"
          }
        ],
      }
      return data
    },
    getDoughnutImmobileNumbers: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      return setFormatForChart(state,'Número de Imóveis','Tamanho')
    },
    getDoughnutDeficitApp: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      return setFormatForChart(state,'Déficit de APP','Tamanho')
    },
    getDoughnutDeficitLR: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      return setFormatForChart(state,'Déficit de RL','Tamanho')
    },
    getStackedBarChartGidxArea: (state) => {
      if(stateIsError(state)) {
        return null;
      }

        return getters.getVerticalBarChartGidxArea(state)       
    },
    getVerticalBarChartNV: (state) => {
       if(stateIsError(state)) {
        return null;
      }

      let chartData = formaVerticalBarChartNV(state)
      const data = {
        labels: chartData[0],
        datasets: [
          {
            label: "Vegetação Nativa dentro de APP",
            data: chartData[1],
            borderColor: "#00853F",
            backgroundColor: "#00853F"
          },
          {
            label: "Vegetação Nativa fora de APP",
            data:chartData[2],
            borderColor: "#FDBB30",
            backgroundColor: "#FDBB30"
          },
          {
            label: "Vegetação Nativa Excedente",
            data:chartData[3],
            borderColor: "#0081C6",
            backgroundColor: "#0081C6"
          },
          {
            label: "CRA",
            data:chartData[4],
            borderColor: "#C0C0C0",
            backgroundColor: "#C0C0C0"
          }
        ],
      }
      return data
    },
    getVerticalBarChartAT: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      let chartData = formaVerticalBarChartAT(state)
      const data = {
        labels: chartData[0],
        datasets: [
          {
            label: "Área Antrópica dentro de APP",
            data: chartData[1],
            borderColor: "#00853F",
            backgroundColor: "#00853F"
          },
          {
            label: "Área Antrópica dentro de APP",
            data:chartData[2],
            borderColor: "#0081C6",
            backgroundColor: "#0081C6"
          }
        ],
      }
      return data
    },
    loadingStatus (state) {
      return state.loadingStatus
    }
  }
  
  // actions
  const actions = {
    getForestAct ({ commit, state} ) {
      setTimeout(() => {
        commit('loadingStatus',true)
        let formatedJson = JSON.stringify({...state.filtersFormated})
        fetch(process.env.VUE_APP_API_URL+`dashboard/forest_act`,{method: "POST",headers: { "Content-Type": "application/json" },body: formatedJson})
        .then(response => response.json())
        .then(function(data) {
          commit('setForestAct', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
          commit('setForestAct', null)
          commit('loadingStatus',false)
          console.error("There was an error!", error);
        });
      });
    },
  }
  
  // mutations
  const mutations = {
    setForestAct (state, { data }) {
        state.all = data
    },
    setFilter (state,_filter) {
      state.loadingStatus = false;
      setFilter(state,_filter)      
    },
    loadingStatus(state, newLoadingStatus) {
        state.loadingStatus = newLoadingStatus
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }