import { stateIsError } from '../../../modules/api.utils';

  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  
  const chartLabel = "Tamanho"

  const setFormatForChart = (state,view) => {
    let labels = [];
    let values = [];
    state.all.forEach((item,index) => { 
      labels[index] = item[chartLabel]
      values[index] = item[view]
    })
    return [labels,values]
  }

  // getters
  const getters = {
    getDeficitsApp: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      return setFormatForChart(state,'Déficit de APP')
    },
    getDeficitsLR: (state) => {
      if(stateIsError(state)) {
        return null;
      }
      
      return setFormatForChart(state,'Déficit de RL')
    },
    loadingStatus (state) {
      return state.loadingStatus
    }
  }
  
  // actions
  const actions = {
    getDeficits ({ commit}, cd_mun ) {
      setTimeout(() => {
        commit('loadingStatus',true)
        fetch(process.env.VUE_APP_API_URL+`forest_act/deficits`,{method: "POST",headers: { "Content-Type": "application/json" },body: JSON.stringify({ "cd_mun": cd_mun })})
        .then(response => response.json())
        .then(function(data) {
          commit('setDeficits', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setDeficits', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
  }
  
  // mutations
  const mutations = {
      setDeficits (state, { data }) {
          state.all = data
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }