<template>
  <a :href="$t(hrefId,['hello'])" target="_blank">
    <img :src="'/assets/'+fileName" :alt="$t(altId,['hello'])" :title="$t(altId,['hello'])" class="m-3 my-md-0  img-fluid">
  </a> 
</template>
<script>
  export default {
    name: 'Logo',
    props: {
      'altId': {
        type:String,
        required:true
      },
      'hrefId': {
        type:String,
        required:true
      },
      'fileName': {
        type:String,
        required:true
      }
    }
  }
</script>

<style lang="scss">
</style>