<template>
    <div id="page-container">
        <Header />
        <div class="container-fluid p-0" id="content-wrap">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" :key="$route.component"/>
                </keep-alive>
            </router-view>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../../organisms/Headers/Main"
import Footer from "../../organisms/Footers/Main"
export default {
    name:"TemplateApp",
    components:{Header,Footer}
}
</script>

<style>
    html, body {
        min-height: 100%;
        padding: 0;
        margin: 0;
    }
    /** Allow footer to have absolute position and stick to the bottom */
    #page-container {
        padding: 72px 0 80px 0; /* header and footer height */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;
    }
    /* #content-wrap {
        max-height: calc(100vh - 150px);
    } */
</style>