<template>
  <App />
</template>
<script>
import App from '../templates/App/App'


export default {
  name: 'PageApp',
  components: {
    App
  }
}
</script>
