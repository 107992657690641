export function setFilter(state, _filter) {
    if (state.filters.find(filter => filter.name == _filter.name && filter.value == _filter.value)) {
        let filters = state.filters.filter(function (value) {
            return ((value.name == _filter.name && value.value != _filter.value) || (value.name != _filter.name && value.value == _filter.value) || (value.name != _filter.name && value.value != _filter.value))
        });
        state.filters = filters
    } else {
      //  state.filters = [] //remove this to allow multiple filters
        state.filters.push(_filter)
    }
    let key_name = _filter.name
    state.filtersFormated[key_name] = []
    for (const [key, item] of Object.entries(state.filters)) {
        key;
        if (item.name == key_name) {
            if (key_name == "cd_mun") {
                if (Array.isArray(item.value)) {
                    state.filtersFormated[item.name] = item.value.map(vl => vl)
                } else {
                    state.filtersFormated[item.name] =  item.value   
                }
            } else {
                state.filtersFormated[item.name].push("'" + item.value + "'")                
            }
        }
    }
    if (state.filtersFormated[key_name].length == 0) {
        delete state.filtersFormated[key_name]
    }
}