<template>
<div class="container-fluid px-0 mx-0">
    <div class="col-12 text-center footer-main pt-0 pt-md-2">
        <Logo altId="logo-alt-sos-mata-atlantica" fileName="logo-sosma.png" hrefId="logo-href-sos-mata-atlantica" class="m-5" />
        <Logo altId="logo-alt-imaflora" fileName="imaflora_novo.png" hrefId="logo-href-imaflora" class="m-5"/>
        <Logo altId="logo-alt-ocf" fileName="logo-ocf1.png" hrefId="logo-href-ocf" class="m-5" />
        <Logo altId="logo-alt-geolab" fileName="logo-geolab.png" hrefId="logo-href-geolab" class="m-5"/>
    </div>
</div>
</template>

<script>
import Logo from "../../atoms/Logo"
export default {
    name:"FooterMain",
    components:{Logo}

}
</script>

<style>
.footer-main {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    height: 80px;
    margin-bottom: -80px;
    background-color: white;
    padding-top: 1rem;
}
</style>