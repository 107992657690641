import { formatNumber, isNumeric } from "./numeric.utils.js"

const noFormatFields = ["Ranking","Número de Imóveis","Imóveis com Déficit"];

export function setFormatForTable (state)  {
    let headers = [];
    let columns = {};
    let rows = [];
    state.all.forEach((item,index) => {
        let count = 0
        let countColumn = 1
        let columName = ''
        let columnValue = ''

        for (const [key, value] of Object.entries(item)) {
            headers[count++] = {"column":key}
            columName = "column"+countColumn++
            columnValue = value;
            let bypassFormat = noFormatFields.find(field => field == key)
            if(isNumeric(columnValue) && !bypassFormat) {
              columnValue = formatNumber(parseFloat(columnValue));
            }

            columns[columName] = columnValue
        }
        rows[index] = {...columns}
    })
    let table = {
        "table": [{
            "header":headers,
            "body":rows
        }]
    }
    return table
  }  
