<template>
    <!-- As a heading -->
    <nav id="header-main" class="navbar navbar-dark">
        <div class="container-fluid">
            <span class="navbar-brand mb-0 mx-auto"><Title /></span>
        </div>
    </nav>
</template>

<script>
import Title from "../../molecules/Titles/Header"
export default {
    name:"HeaderMain",
    components:{Title}
}
</script>

<style lang="scss">
    #header-main {
        margin-top: -72px;
        height: 70px;
        background-color:#00853F;
    }
</style>