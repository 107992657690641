import { slugify } from '../../modules/text.utils'

  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  

  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getFormatedStates: (state) => {
      let formated = [];
      state.all.forEach((item,index) => { 
        formated[index] = {cd_uf: item.cd_uf,nm_uf:item.nm_uf + " (" + item.sigla_uf + ")"}
      })
      return formated
    },
    getFilteredStates: (state) => {
      let formated = [];
      state.all.forEach((item) => { 
        if(state.filterUfs.includes(item.cd_uf)) {
          let name = item.nm_uf + " - " + item.sigla_uf;
          let slug = slugify(item.nm_uf);
          formated.push({
              nm_uf: item.nm_uf,
              label_uf: name,
              sigla_uf: item.sigla_uf,
              slug_uf: slug,
              cd_uf: item.cd_uf
            })
        }
      })
      return formated
    }
  }
  
  // actions
  const actions = {
    getAllStates ({ commit }) {
      commit('loadingStatus',true)
      fetch(process.env.VUE_APP_API_URL+`filters/uflist`)
      .then(response => response.json())
      .then(function(data) {  
        commit('setStates', {data})
        commit('loadingStatus',false)
    });
    },
  }
  
  // mutations
  const mutations = {
     setStates (state, { data }) {
          state.all = data
      },
      setFilter(state, _filter) {
        state.filterUfs = _filter;
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }