export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "no-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retornou zero"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "title-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataforma do Código Florestal da<br class='d-block d-md-none' /> Mata Atlântica"])},
  "title-session-slide-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundiário - Limites da Malha Fundiária (apenas exemplo) Item 1"])},
  "title-session-slide-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundiário - Limites da Malha Fundiária (apenas exemplo) Item 2"])},
  "title-session-slide-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundiário - Limites da Malha Fundiária (apenas exemplo) Item 3"])},
  "title-session-slide-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundiário - Limites da Malha Fundiária (apenas exemplo) Item 4"])},
  "title-session-slide-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo de big numbers"])},
  "title-chart-doughnut-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporção do número de imóveis por tamanho de imóvel"])},
  "title-chart-doughnut-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico 2"])},
  "title-chart-doughnut-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico 3"])},
  "title-chart-doughnut-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico 4"])},
  "title-chart-doughnut-deficit-app-ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déficit de APP (ha)"])},
  "title-chart-doughnut-deficit-rl-ha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déficit de RL (ha)"])},
  "title-dashboard-table-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacões fundiárias"])},
  "title-chart-bar-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporção do número e área ocupada por tamanho de imóvel"])},
  "title-chart-bar-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporção da vegetação nativa dentro e fora de APP e Cota de Reserva Ambiental por tamanho de imóvel"])},
  "title-chart-bar-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporção da área antrópica dentro e fora de APP e por tamanho de imóvel"])},
  "title-chart-bar-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico Barra 4"])},
  "title-filter-box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar Camadas"])},
  "title-page-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados"])},
  "title-legend-box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg"])},
  "title-filter-your-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre a sua pesquisa"])},
  "title-table-rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking dos maiores déficits totais"])},
  "link-clear-layers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar camadas"])},
  "link-see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])},
  "link-back-to-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao mapa"])},
  "link-back-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar ao topo"])},
  "close-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
  "badge-counter-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de filtros: "])},
  "button-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "button-access-map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acessar mapa"])},
  "button-access-dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acessar painel"])},
  "button-dash-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "button-dash-clean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar filtros e ver total da base"])},
  "logo-alt-sos-mata-atlantica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOS MATA ATLÂNTICA"])},
  "logo-alt-imaflora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMAFLORA"])},
  "logo-alt-ocf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observatório do Código Florestal"])},
  "logo-alt-geolab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GeoLab"])},
  "logo-href-sos-mata-atlantica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.sosma.org.br/"])},
  "logo-href-imaflora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.imaflora.org/"])},
  "logo-href-ocf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://observatorioflorestal.org.br/"])},
  "logo-href-geolab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://geolabusp.wixsite.com/site"])},
  "banner-alt-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner principal na HOME"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
  "paragraph-1-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Plataforma do Código Florestal reúne dados sobre a situação da implementação da Lei 12.651 na Mata Atlântica. O principal objetivo é auxiliar a elaboração de Programas Estaduais de Regularização Ambiental (PRA), compromissos voluntários privados, investimentos públicos ou privados de incentivo e apoiar decisões de planejamento territorial que possam otimizar a implementação do Código, além de dar maior transparência ao status atual."])},
  "paragraph-2-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigente deste 2012, o Código Florestal estabelece aspectos gerais de proteção da vegetação, limites e regras para uso do meio ambiente, instrumentos econômicos e financeiros para o alcance de seus objetivos e as formas de controle da exploração florestal. A primeira etapa para a implementação da lei é a inscrição dos imóveis no Cadastro Ambiental Rural (CAR)."])},
  "paragraph-3-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A partir das informações constantes no CAR, a Plataforma estima o déficit de vegetação nativa em Áreas de Proteção Permanente (APP) e em Reservas Legais (RL), no bioma Mata Atlântica, bem como os excedentes que podem ser utilizados em ações de compensação. A plataforma permite, ainda, avaliar alterações na cobertura de vegetação natural entre 2008 e 2020, que indicam possíveis mudanças entre excedentes e déficits estimados após a data de análise dos imóveis definida pelo Código Florestal."])},
  "paragraph-4-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As informações que subsidiam a Plataforma fazem parte do estudo <a href='https://observatorioflorestal.org.br/en/estudo-o-codigo-florestal-na-mata-atlantica/?doing_wp_cron=1652101401.4324040412902832031250' target='_blank'>O Código Florestal na Mata Atlântica</a>, publicado em setembro de 2021. Esta iniciativa é uma parceria entre a SOS Mata Atlântica, o Imaflora, o Observatório do Código Florestal e o GeoLab Esalq/USP."])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados disponíveis e forma de consulta"])},
  "paragraph-5-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Plataforma do Código Florestal estão disponíveis dados sobre as estimativas de déficit de Áreas de Preservação Permanente (APPs) e Reservas Legais (RL) que podem ser consultadas por estados e por municípios do bioma Mata Atlântica ou também por tamanho dos imóveis: pequenos (menores que 4 módulos fiscais), médios (entre 4 e 15 módulos fiscais) e grandes (maiores que 15 módulos fiscais). Ao final há um ranking com a estimativa do déficit de APP e RL dos estados e municípios da Mata Atlântica."])},
  "paragraph-6-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Plataforma contém ainda a estimativa de excedente de vegetação nativa por município e por estado. Esta é a vegetação nativa que existe nos imóveis rurais além dos requisitos de APP e RL, que está protegida pela Lei da Mata Atlântica e pode ser usada para a compensação do requisito de Reserva Legal."])},
  "paragraph-7-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para cada município e estado também estão disponíveis para consulta os dados fundiários, com a distribuição do território entre terras públicas e privadas em várias categorias, como Unidades de Conservação, terras indígenas, área urbana e imóveis privados pequenos, médios e grandes."])},
  "paragraph-8-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os dados podem ser acessados na forma de gráficos e tabelas em <a href='/dashboard'>“Acessar Painel”</a> ou diretamente pelo mapa, em <a href='/mapa'>“Acessar Mapa”</a>, onde é possível acessar e visualizar os dados gerais de maneira geoespacial."])},
  "paragraph-9-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para consultar a área de regeneração natural e de desmatamento que ocorreu após 2008, clique em <a href='/mapa'>“Acessar Mapa”</a>, na opção “Dinâmica da Vegetação Nativa”. Nesta opção também estará disponível uma camada com o mapa do uso da terra no bioma em 2008 e 2018, extraído da Coleção 5 do Mapbiomas."])},
  "paragraph-10-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em caso de dúvidas ou para reportar erros, entre em contato por: <a href='mailto:info", "@", "sosma.org.br' target='_blank'>info", "@", "sosma.org.br</a><br /> Para atendimento à imprensa, o contato é <a href='mailto:joao", "@", "pensatacom.com' target='_blank'>joao", "@", "pensatacom.com</a>"])},
  "position-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["O município de ", _interpolate(_named("name")), " está na ", _interpolate(_named("pos")), "º posição do rank"])},
  "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pequeno"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])},
  "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grande"])},
  "Imóveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imóveis"])},
  "Hectares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hectares"])},
  "no-deficit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há déficit"])}
}