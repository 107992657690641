import {setFormatForTable} from '../../../modules/table.utils'
import { stateIsError } from '../../../modules/api.utils';

  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  
  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getRankUf: (state) => {
      if(stateIsError(state)) {
        return null;
      }

      return setFormatForTable(state)
    }
  }
  
  // actions
  const actions = {
    getRankUf ({ commit }) {
      setTimeout(() => {
        commit('loadingStatus',true)
        fetch(process.env.VUE_APP_API_URL+`dashboard/forest_act_rank_uf`,{method: "POST",headers: { "Content-Type": "application/json" }})
        .then(response => response.json())
        .then(function(data) {
          commit('setRankUf', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setRankUf', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      },1000);
    },
  }
  
  // mutations
  const mutations = {
      setRankUf (state, { data }) {
          state.all = data
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }