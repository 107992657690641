<template>
        <div class="text-center">
            CARREGANDO
        </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" >
</style>