  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  

  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getLandTenure: (state) => {
      return state.all
    }
  }
  
  // actions
  const actions = {
    getLandTenure ({ commit }) {
      commit('loadingStatus',true)
      fetch(process.env.VUE_APP_API_URL+`filters/landtenure`)
      .then(response => response.json())
      .then(function(data) {
        commit('setLandTenure', {data})
        commit('loadingStatus',false)
    });
    },
  }
  
  // mutations
  const mutations = {
      setLandTenure (state, { data }) {
          state.all = data
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }