<template>
   <slot>{{$t(id,['hello'])}}</slot>
</template>
<script>

export default {
  name: 'Title',
  props: {
    'id': {
      type:String,
      required:false
    }
  }
}
</script>

<style lang="scss">
.title {
  font-family: 'Poppins', sans-serif;  
  font-weight: bold;
}
</style>