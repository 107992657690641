import { createWebHistory, createRouter } from "vue-router";
const Home = () => import(/* webpackChunkName: "home" */ './components/pages/Home.vue')
const Map = () => import(/* webpackChunkName: "map" */ './components/pages/Map.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './components/pages/Dashboard.vue')


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },   
  {
    path: "/mapa",
    name: "map",
    component: Map,
  }, 
  {
    path: "/dashboard/:cd_uf/:cd_mun*",
    name: "dashboard",
    component: Dashboard,
  }, 
  {
    path: "/dashboard",
    name: "dashboardNoParam",
    component: Dashboard,
  },   
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;