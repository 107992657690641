import {setFormatForTable} from '../../../modules/table.utils'
import { stateIsError } from '../../../modules/api.utils'

  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  
  // getters
  const getters = {
    getLtenureFormated: (state) => {
      if(stateIsError(state)) {
        return null;
      }
      return setFormatForTable(state)
    },
    loadingStatus (state) {
      return state.loadingStatus
    }
  }
  
  // actions
  const actions = {
    getLtenure ({ commit}, cd_mun ) {
      setTimeout(() => {
        commit('loadingStatus',true)
        fetch(process.env.VUE_APP_API_URL+`forest_act/ltenure`,{method: "POST",headers: { "Content-Type": "application/json" },body: JSON.stringify({ "cd_mun": cd_mun })})
        .then(response => response.json())
        .then(function(data) {
          commit('setLtenure', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setLtenure', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
  }
  
  // mutations
  const mutations = {
      setLtenure (state, { data }) {
          state.all = data
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }