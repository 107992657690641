import { stateIsError, nullToZero } from '../../../modules/api.utils';

// initial state
  const state = () => ({
    all: [],
    loadingStatus:true
  })
  

  // getters
  const getters = {
    getBigNumbersCount (state) {
      if(stateIsError(state)) {
        return 0;
      }

      return nullToZero(state.all["Número de Imóveis"])
    },
    getBigNumbersArea (state) {
      if(stateIsError(state)) {
        return 0;
      }

      return nullToZero(state.all["Área (ha)"])
    },
    loadingStatus (state) {
      return state.loadingStatus
    }
  }
  
  // actions
  const actions = {
    getBigNumbers ({ commit}, cd_mun ) {
      setTimeout(() => {
        commit('loadingStatus',true)
        fetch(process.env.VUE_APP_API_URL+`forest_act/bignumbers`,{method: "POST",headers: { "Content-Type": "application/json" },body: JSON.stringify({ "cd_mun": cd_mun })})
        .then(response => response.json())
        .then(function(data) {
          commit('setBigNumbers', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setBigNumbers', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
  }
  
  // mutations
  const mutations = {
      setBigNumbers (state, { data }) {
          state.all = data[0]
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }