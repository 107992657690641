  
  import { slugify } from '../../modules/text.utils'

  // initial state
  const state = () => ({
    all: [],
    loadingStatus:true,
    filterCities: []
  })
  

  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getFormatedCities: (state) => {
      let formated = [];
      state.all.forEach((item,index) => { 
        formated[index] = {cd_mun: item.cd_mun,nm_mun:item.nm_mun + " - " + item.sigla_uf ,sigla_uf: item.sigla_uf}
      })
      return formated
    },
    getFilteredCities: (state) => {
      let formated = [];
      state.all.forEach((item) => { 
        if(state.filterCities.includes(item.cd_mun.toString())) {
          let name = item.sigla_uf + " - " + item.nm_mun;
          let slug = slugify(item.nm_mun + "-" + item.sigla_uf);
          formated.push({cd_mun: item.cd_mun,nm_mun:name,sigla_uf: item.sigla_uf,slug_mun: slug})
        }
      })
      return formated
    }
  }
  
  // actions
  const actions = {
    getAllCities ({ commit }) {
      commit('loadingStatus',true)
      fetch(process.env.VUE_APP_API_URL+`filters/munlist`)
      .then(response => response.json())
      .then(function(data) {
        commit('setCities', {data})
        commit('loadingStatus',false)
    });
    },
  }
  
  // mutations
  const mutations = {
      setCities (state, { data }) {
          state.all = data
      },
      setFilter(state, _filter) {
         state.filterCities = _filter;
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }