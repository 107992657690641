export default {
  "item1": [
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3855670"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Original do Imovél"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARQUE ESTADUAL DA SERRA DO MAR"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho do Imóvel"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área final do Imóvel (pós processado) (ha)"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["270031.57"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área original do Imóvel"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["322293.46"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bioma"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MATA ATLÂNTICA"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Municipal IBGE"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3555406"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imóvel Público ou Privado"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terra pública"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do Município"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UBATUBA"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidade da Federação"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SP"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Mesorregião"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALE DO PARAÍBA PAULISTA"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudeste"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria Fundiária"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UC Proteção Integral"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da Microrregião"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARAGUATATUBA"])}
    }
  ],
  "item4": [
    {
      "table": [
        {
          "header": [
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna1"])}
            },
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna2"])}
            },
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna3"])}
            },
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna4"])}
            },
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna5"])}
            },
            {
              "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coluna6"])}
            }
          ],
          "body": [
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row11"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row20"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row29"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row38"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row47"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row56"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row12"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row21"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row30"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row39"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row48"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row57"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row13"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row22"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row31"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row40"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row49"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row58"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row14"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row23"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row32"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row41"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row50"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row59"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row15"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row24"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row33"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row42"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row51"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row60"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row16"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row25"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row34"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row43"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row52"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row61"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row17"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row26"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row35"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row44"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row53"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row62"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row18"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row27"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row36"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row45"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row54"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row63"])}
            },
            {
              "column1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row19"])},
              "column2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row28"])},
              "column3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row37"])},
              "column4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row46"])},
              "column5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row55"])},
              "column6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["row64"])}
            }
          ]
        }
      ]
    }
  ],
  "item5": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 1"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 2"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 3"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 4"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 5"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 6"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 7"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 8"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 9"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum 10"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100%"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Lorem Ipsum"])}
    }
  ]
}