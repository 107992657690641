import {setFormatForTable} from '../../../modules/table.utils'
import {setFilter} from '../../../modules/filter.utils'
import { stateIsError } from '../../../modules/api.utils'

  // initial state
  const state = () => ({
    all: [],
    filters: [],
    filtersFormated: [],
    loadingStatus:true
  })
  
  // getters
  const getters = {
    loadingStatus (state) {
      return state.loadingStatus
    },
    getLandTenure: (state) => {
      if(stateIsError(state)) {
        return null;
      }
      return setFormatForTable(state)
    }
  }
  
  // actions
  const actions = {
    getLandTenure ({ commit, state }) {
      setTimeout(() => {
        commit('loadingStatus',true)
        let formatedJson = JSON.stringify({...state.filtersFormated})
        fetch(process.env.VUE_APP_API_URL+`dashboard/land_tenure`,{method: "POST",headers: { "Content-Type": "application/json" },body: formatedJson})
        .then(response => response.json())
        .then(function(data) {
          commit('setLandTenure', {data})
          commit('loadingStatus',false)
        })
        .catch(error => {
            commit('setLandTenure', null)
            commit('loadingStatus',false)
            console.error("There was an error!", error);
        });
      }, 1000);
    },
  }
  
  // mutations
  const mutations = {
      setLandTenure (state, { data }) {
          state.all = data
      },
      setFilter (state,_filter) {
        state.loadingStatus = false;
        setFilter(state,_filter)
      },
      loadingStatus(state, newLoadingStatus) {
          state.loadingStatus = newLoadingStatus
      }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }