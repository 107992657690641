<template>
  <Title>
     <h1 class="title header text-center">
         <a href="/" v-html="$t('title-header')"> </a>
    </h1>
  </Title>
</template>

<script>
import Title from "@/components/atoms/Title"
export default {
    name:"TitleHeader",
    components:{Title}
}
</script>

<style lang="scss">
    .title {
        &.header {
            font-size: 1.125rem;
            line-height: 27px;
            color: #FFFFFF;  
        }
    }
        .title {
        &.header a{
            font-size: 1.125rem;
            line-height: 27px;
            color: #FFFFFF!important;  
            text-decoration: none;
        }
    }
</style>