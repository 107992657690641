export default {
  "legends": [
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apicum"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apicum"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#978B4C"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corpos d'água"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corpos-dagua"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#FD8C26"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apicum"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apicum2"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#D4A7BE"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afloramento rochoso"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afloramento-rochoso"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#C07CA0"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agricultura anual e perene"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agricultura-anual-perene"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#4EC1A6"])}
    },
    {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afloramento rochoso"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afloramento-rochoso2"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#FCBA41"])}
    }
  ]
}